import React from 'react';
import Script from 'next/script';
import NextHead from 'next/head';
import { useLogPage } from '../utils/amplitude';

interface HeadProps {
  title: string;
  description: string;
  pageName: string;
  pageProps?: any;
}

export const Head: React.FC<HeadProps> = props => {
  useLogPage(props.pageName, props.pageProps);

  return (
    <>
      <NextHead>
        <title>{props.title}</title>
        <meta name="description" content={props.description} />

        <meta property="og:title" content={props.title} />
        <meta property="og:description" content={props.description} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://exodia.io/" />
        <meta property="og:image" content="https://exodia.io/images/og.png" />
        <meta name="twitter:image" content="https://exodia.io/images/og.png" />

        <link rel="icon" href="/favicon.ico" />
      </NextHead>
      {/* https://nextjs.org/docs/messages/no-script-tags-in-head-component */}
      <Script defer data-domain="exodia.io" src="https://plausible.io/js/plausible.js" />
    </>
  );
};
