import React from 'react';
import Image from 'next/image';
import classNames from 'classnames';
import { Button } from '@components/Button';
import shareMintImage from './images/sharemint-logo.png';
import erc721rImage from './images/erc721rt.png';
import exodiaImage from './images/exopurple.png';

interface Feature {
  title: string;
  body: string;
  buttonTitle?: string;
  href?: string;
  onClick?: () => void;
  image: React.ReactNode;
}

export const Features: React.FC<{}> = props => {
  const features: Array<Feature> = [
    {
      title: 'ShareMint',
      body: `Launch a web3 native affiliate or referral program in minutes.`,
      buttonTitle: 'Visit Website',
      href: `https://sharemint.xyz`,
      image: <Image src={shareMintImage} alt="" />,
    },
    {
      title: 'ERC721R',
      body: 'The refundable NFTs standard.',
      buttonTitle: 'Visit Website',
      href: `https://erc721r.org`,
      image: <Image src={erc721rImage} alt="" />,
    },
    {
      title: 'Exclusive Community',
      body: `Moon Node, NFT alpha, allow-lists priority, web3 affilaites bonus and more.`,
      buttonTitle: 'Join Discord',
      href: `https://discord.gg/r5MgnGjDha`,
      image: <Image src={exodiaImage} alt="" />,
    },
    // {
    //   title: 'Mint analytics',
    //   body: 'See which collections are minting in real time. Instantly follow mint data and decide whether to join in on the action.',
    //   image: <Image src={mintImage} alt="" />,
    // },
    // {
    //   title: 'Trait based price charts',
    //   body: `Refine your rarity strategy by charting differences in price for various collection traits. Visualize liquidity for different traits by spotting trait-specific trading volume.`,
    //   image: <Image src={traitsImage} alt="" />,
    // },
    // {
    //   title: 'Multi wallet porftolio tracker',
    //   body: `Follow the holdings and profit levels of multiple wallets with ease. Know when certain wallets are minting, buying, selling, or receiving any NFT.`,
    //   image: <Image src={walletImage} alt="" />,
    // },
    // {
    //   title: 'Exclusive alpha group',
    //   body: `Join our community of pro-traders in our alpha channel. We have one of the strongest and most exclusive alpha groups in the space, run by our dedicated analysts making daily NFT calls.`,
    //   image: <Image src={listingsImage} alt="" />,
    // },
    // {
    //   title: 'Find Opportunities Now',
    //   description: [
    //     { body: 'Set up alerts based on collections and smart wallets' },
    //     { body: 'Follow real time sales, lists and mints' },
    //     { body: 'Analyize multiple wallets simultaneously' },
    //   ],
    //   image: <Image src={firehoseImage} alt="" />,
    // },
    // {
    //   title: 'Discover Hidden Gems',
    //   description: [
    //     {
    //       body: `Valuate NFT collections using our unique rarity analysis tools, including trait rarity and score.`,
    //     },
    //     {
    //       body: `Analyize trait floor prices for any collection.`,
    //     },
    //   ],
    //   image: <Image src={rarityImage} alt="" />,
    // },
  ];

  return (
    <div className="space-y-16 md:space-y-20">
      {features.map((feature, index) => (
        <FeatureImage
          key={feature.title}
          imageSide={(index / 2) % 1 === 0 ? 'left' : 'right'}
          {...feature}
        />
      ))}
    </div>
  );
};

const FeatureImage = (feature: Feature & { imageSide: 'right' | 'left' }) => {
  return (
    <div key={feature.title} className="grid lg:grid-cols-2 gap-16">
      <div
        className={classNames('max-w-md flex flex-col justify-center mx-auto', {
          'lg:order-2': feature.imageSide === 'left',
        })}
      >
        <p className="font-grifter text-2xl md:text-4xl">{feature.title}</p>
        <div className="mt-4">
          <div className="text-lg md:text-xl">{feature.body}</div>
          {feature.buttonTitle ? (
            <div className="mt-10">
              <Button
                color={'gradient-v2'}
                rounded
                href={feature.href}
                asLink
                onClick={feature.onClick}
                size={'sm'}
              >
                <span>{feature.buttonTitle}</span>
              </Button>
            </div>
          ) : null}
        </div>
      </div>
      <div className={classNames({ 'lg:order-1': feature.imageSide === 'left' })}>
        <div className="text-center shadow-xl">{feature.image}</div>
      </div>
    </div>
  );
};
