import React from 'react';
import classNames from 'classnames';
import { useTheme } from 'next-themes';
import Link from 'next/link';
import { Loading } from './Loading';
import { useMounted } from '@utils/hooks/useMounted';
// import { LoadingSmall } from "./Loading";

// export interface ButtonProps {
//   styleType?: 'primary' | 'secondary' | 'danger' | 'link' | 'linkWhite' | 'white' | 'black';
//   icon?: React.ReactNode;
//   size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'icon';
//   block?: boolean;
//   disabled?: boolean;
//   loading?: boolean;
//   padding?: string;
//   onDark?: boolean;
// }
//
// export type FullButtonProps = ButtonProps &
//   React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

function ButtonComponent(
  // props: React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
  props: any
) {
  return (
    <button type="button" {...props}>
      {props.children}
    </button>
  );
}

function LinkComponent(
  // props: React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>
  props: any
) {
  return (
    <Link href={props.href!} prefetch={props.prefetch ?? false}>
      <a {...props}>{props.children}</a>
    </Link>
  );
}

function getPrimaryColor(theme?: string): ButtonColor {
  return theme === 'light' ? 'primary' : 'gradient-v2';
}

export type ButtonColor =
  | 'green'
  | 'blue'
  | 'yellow'
  | 'white'
  | 'black'
  | 'gray'
  | 'transparent'
  | 'transparent-v2'
  | 'gradient'
  | 'gradient-v2'
  | 'primary';

export type ButtonSize = 'sm' | 'lg' | 'xl' | 'xs'; // 'sm' | 'md' |;

export interface ButtonProps {
  color?: ButtonColor;
  size?: ButtonSize;
  block?: boolean;
  link?: boolean;
  asLink?: boolean;
  loading?: boolean;
  rounded?: boolean;
  fullWidth?: boolean;
}

export type FullButtonProps = ButtonProps &
  React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> &
  React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>;

export const Button: React.FC<FullButtonProps> = props => {
  const {
    color: buttonColor = 'gradient',
    size = 'lg',
    block,
    link,
    asLink,
    loading,
    rounded,
    fullWidth,
    ...otherProps
  } = props;
  const { theme } = useTheme();
  // from next-themes docs sample code. as we don't have theme on server
  const mounted = useMounted();

  if (!mounted) return null;

  const Wrapper = asLink ? LinkComponent : ButtonComponent;

  const color = buttonColor === 'primary' ? getPrimaryColor(theme) : buttonColor;

  return (
    <Wrapper
      {...otherProps}
      className={classNames(
        'font-grifter whitespace-nowrap text-center flex items-center justify-center',
        {
          'w-full': fullWidth,
          'bg-neon-green-500 disabled:bg-neon-green-500 text-black disabled:hover:bg-neon-green-500 hover:bg-neon-green-700':
            color === 'green',
          'bg-neon-blue-500 disabled:bg-neon-blue-500 text-black disabled:hover:bg-neon-blue-500 hover:bg-neon-blue-700':
            color === 'blue',
          'bg-neon-yellow-500 disabled:bg-neon-yellow-500 text-black disabled:hover:bg-neon-yellow-500 hover:bg-neon-yellow-700':
            color === 'yellow',
          'bg-primary-light-mode-main dark:bg-secondary-dark-mode-main text-white dark:text-black disabled:bg-primary-light-mode-main dark:disabled:bg-secondary-dark-mode-main disabled:hover:bg-whitehover:bg-gray-400':
            color === 'primary',
          'bg-white text-black disabled:bg-white disabled:hover:bg-whitehover:bg-gray-400':
            color === 'white',
          'bg-black text-white disabled:opacity-20 disabled:hover:text-opacity-100 hover:text-opacity-60':
            color === 'black',
          'bg-gray-200 dark:bg-gray-700 text-gray-400 hover:bg-gray-300 hover:text-gray-500 dark:hover:bg-gray-600 dark:hover:text-gray-300':
            color === 'gray',
          'disabled:opacity-100 hover:opacity-60': color === 'transparent',
          'opacity-70 hover:opacity-100': color === 'transparent-v2',
          'bg-gradient-to-r from-neon-yellow-500 to-neon-green-500 text-black disabled:opacity-100 hover:opacity-60':
            color === 'gradient',
          'bg-gradient-v2 text-black hover:opacity-80': color === 'gradient-v2',
          rounded: rounded,
          'font-bold': size === 'sm',
          'text-lg font-bold': size === 'lg',
          'text-xl font-bold': size === 'xl',
          'px-2 pt-1.5 pb-1 text-xs': size === 'xs' && !link,
          'px-3 pt-2 pb-1': size === 'sm' && !link,
          'px-12 pt-6 pb-5': size === 'lg' && !link,
          'px-8 pt-7 pb-6': size === 'xl' && !link,
          // can affect other block buttons
          'w-full block px-0 align-center': block,
          'disabled:opacity-40 disabled:cursor-default': props.disabled,
          'flex justify-center items-center space-x-4': loading,
        }
      )}
    >
      <span>{props.children}</span>
      {loading && <Loading size="xs" color={color === 'black' ? 'white' : 'black'} />}
    </Wrapper>
  );
};
