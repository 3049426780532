import React from 'react';
import Image from 'next/image';
import { Description, Heading } from '../Typography';
import lazyLionsImage from './images/lltemp.png';
import kaijuKingzImage from './images/kaiju.png';
import dawImage from './images/daives.png';
import curiousAddysImage from './images/curious.png';
import nfpImage from './images/nfpeople.png';
import cfaImage from './images/cfa.png';

interface HeroProps {}

export const Hero: React.FC<HeroProps> = props => {
  return (
    <div className="bg-black">
      <div className="relative">
        <div className="mt-16 mx-auto max-w-7xl px-4 sm:mt-24 sm:px-6">
          <div className="text-center">
            <div className="text-md font-grifter font-bold w-auto inline-block dark:text-transparent dark:bg-clip-text dark:bg-gradient-to-br dark:from-neon-yellow-500 dark:to-neon-blue-500 dark:selection:text-white">
              <Heading inline>Exodia Labs</Heading>
            </div>
            <Description>Building the infrastructure for mainstream web3</Description>
            {/* <div className="mt-8 md:mt-12 flex justify-center">
              <Button
                color={'gradient-v2'}
                size={buttonSize}
                rounded
                href={`${BASE_URL}/dashboard`}
                rel="noreferrer"
                asLink
                onClick={logAccessFreeMainCTA}
              >
                <span>Access for free</span>
              </Button>
            </div> */}
          </div>
        </div>
      </div>
      {/* <div className="text-center hidden md:block max-w-7xl mx-auto mt-20">
        <Image src={dashboardImage} alt="" className="rounded-md" />
      </div> */}
      <div className="bg-black">
        <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
          <h2 className="text-center text-gray-400 text-sm font-semibold uppercase tracking-wide">
            Partnering with top collections:
          </h2>
          <div className="mt-8 grid grid-cols-2 gap-8 md:grid-cols-7 lg:grid-cols-6">
            <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
              <div className="h-12">
                <Image src={kaijuKingzImage} alt="" />
              </div>
            </div>
            <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
              <div className="h-12">
                <Image src={lazyLionsImage} alt="" />
              </div>
            </div>
            <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
              <div className="h-12">
                <Image src={dawImage} alt="" />
              </div>
            </div>
            <div className="col-span-1 flex justify-center md:col-span-3 lg:col-span-1">
              <div className="h-12">
                <Image src={curiousAddysImage} alt="" />
              </div>
            </div>
            <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
              <div className="h-12">
                <Image src={nfpImage} alt="" />
              </div>
            </div>
            <div className="col-span-1 flex justify-center md:col-span-3 lg:col-span-1">
              <div className="h-12">
                <Image src={cfaImage} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
