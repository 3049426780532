import classNames from 'classnames';
import React from 'react';

interface LoadingProps {
  size?: 'xs' | 'sm' | 'md' | 'xl';
  color?: 'green' | 'black' | 'white';
  inline?: boolean;
}

export const Loading: React.FC<LoadingProps> = props => {
  const { size = 'xl', color = 'green' } = props;
  return (
    <div
      className={classNames({
        'p-0': size === 'xs',
        'p-4': size === 'sm',
        'p-8': size === 'md',
        'p-16': size === 'xl',
      })}
    >
      <div className="flex justify-center items-center">
        <div
          className={classNames('animate-spin rounded-full  border-b-2 ', {
            'h-5 w-5': size === 'xs',
            'h-8 w-8': size === 'sm',
            'h-16 w-16': size === 'md',
            'h-32 w-32': size === 'xl',
            'border-neon-green-light-mode dark:border-neon-green-500': color === 'green',
            'border-black': color === 'black',
            'border-white': color === 'white',
          })}
        ></div>
      </div>
    </div>
  );
};
